// Components
import AppLayout from '@/modules/primevue/components/AppLayout.vue'
import { RouteRecordRaw } from 'vue-router'
import productionSheetRoutes from './_production-sheet'
import { removeTrailingSlash } from '@/modules/primevue/helpers/string-manipulation'

const basePath = '/pr'

let routes: Array<RouteRecordRaw>

if (import.meta.env.VITE_PRODUCTIONS_ENABLED === 'true') {
    routes = [
        {
            path: '/productions',
            name: 'Produzioni Legacy',
            redirect: `${basePath}/planner`,
        },
        {
            path: basePath,
            name: 'Produzioni',
            redirect: `${basePath}/planner`,
            component: AppLayout,
            children: [
                {
                    path: `${basePath}/planner`,
                    name: 'Planner produzioni',
                    meta: {
                        title: 'Planner Produzioni',
                    },
                    component: () =>
                        import(
                            '@/modules/production/components/ProductionsPlanner.vue'
                        ),
                },
                {
                    path: `${basePath}/production/add`,
                    name: 'Aggiungi produzione',
                    meta: {
                        title: 'Aggiungi Produzione',
                    },
                    component: () =>
                        import(
                            '@/modules/production/components/production/ProductionAdd.vue'
                        ),
                },

                productionSheetRoutes,

                {
                    path: `${basePath}/binder/:id`,
                    name: 'Raccoglitori',
                    meta: {
                        // title: '◌',
                    },
                    redirect: (to) =>
                        removeTrailingSlash(to.fullPath) + '/general',
                    component: () =>
                        import(
                            '@/modules/production/components/binder/BinderSheet.vue'
                        ),
                    children: [
                        {
                            path: 'general',
                            name: 'Scheda raccoglitore generale',
                            component: () =>
                                import(
                                    '@/modules/production/components/binder/BinderGeneral.vue'
                                ),
                        },
                    ],
                },
            ],
        },
    ]
} else {
    routes = [
        {
            path: '/productions',
            name: 'Produzioni Legacy',
            redirect: `/ab/index`,
        },
        {
            path: `${basePath}/:sub?`,
            redirect: '/ab/index',
            component: AppLayout,
            children: [],
        },
    ]
}

export const ProductionsRouter = routes
