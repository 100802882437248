<script lang="ts">
import AppMenuItem from './AppMenuItem.vue'
import { useMenuStore } from '@/modules/primevue/stores/menu-store'
import { computed } from 'vue'

export default {
    name: 'AppMenu',
    components: {
        AppMenuItem,
    },
    setup() {
        const menuStore = useMenuStore()

        return {
            model: computed(() => menuStore.menu),
        }
    },
}
</script>

<template>
    <ul class="layout-menu">
        <template v-for="(item, i) in model" :key="item">
            <AppMenuItem v-if="!item.separator" :item="item" root :index="i" />

            <li v-else class="menu-separator"></li>
        </template>
    </ul>
</template>
