const defaultTitleLong = 'MadeInShow',
    defaultTitleShort = ' | MIS'

let routeTitle: string | null = null,
    pageTitle: string | null = null

export const documentTitleReset = () => {
    routeTitle = null
    pageTitle = null
    documentTitleManager()
}

export const setRouteTitle = (title: string, resetFirst = false) => {
    if (resetFirst) {
        documentTitleReset()
    }

    routeTitle = title
    documentTitleManager()
}

export const setPageTitle = (title: string) => {
    pageTitle = title

    documentTitleManager()
}

/**
 * Sets the document title based on page title, route title and default title (if none is set)
 */
export const documentTitleManager = () => {
    let calcTitle = defaultTitleLong
    if (pageTitle) {
        calcTitle = pageTitle + defaultTitleShort
    } else if (routeTitle) {
        calcTitle = routeTitle + defaultTitleShort
    }

    document.title = calcTitle
}
