<template lang="pug">
//- transition(name="fade" mode="out-in")
if showBigLoader
    .layout-preloader-container
        .layout-preloader
            span
router-view
toast
</template>

<script lang="ts">
import { computed, watch } from 'vue'
import { setDefaultOptions } from 'date-fns'
import { enUS, it } from 'date-fns/locale'
import { useUiStore } from '@/modules/primevue/stores/ui-store'
import { useCurrentUserStore } from '@/modules/user/stores/current-user-store'
import { usePrimeVue } from 'primevue/config'
import Toast from 'primevue/toast'

import { it as primeVueItIT } from '@/modules/primevue/locales/it-IT.json'
import { en as primeVueEnUS } from '@/modules/primevue/locales/en-US.json'
import { useToast } from 'primevue/usetoast'

export default {
    name: 'App',
    components: { Toast },
    setup() {
        const uiStore = useUiStore()
        const userStore = useCurrentUserStore()
        const PrimeVue = usePrimeVue()

        uiStore.SET_TOAST_SERVICE(useToast())

        // Language locale
        watch(
            () => uiStore.locale,
            () => {
                if (uiStore.locale === 'it') {
                    setDefaultOptions({ locale: it })
                    PrimeVue.config.locale = primeVueItIT
                } else {
                    setDefaultOptions({ locale: enUS })
                    PrimeVue.config.locale = primeVueEnUS
                }
            },
            { immediate: true },
        )

        if (import.meta.env.VITE_LOGIN_PROVIDER === 'cognito') {
            userStore.GET_COGNITO_SESSION().then(() => {
                userStore.FETCH_USER_DATA()
            })
        } else {
            setInterval(function () {
                userStore.FETCH_USER_DATA()
            }, 30000)
        }

        return {
            showBigLoader: computed(() => uiStore.showBigLoader),
            showLoader: computed(() => uiStore.isLoading),
        }
    },
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
