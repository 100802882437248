// Declare state
import { useCurrentUserStore } from '@/modules/user/stores/current-user-store'
import { MenuItem } from 'primevue/menuitem'
import router from '@/router'

function goToKeyAsRoute(this: MenuItem) {
    if (this.key) return router.push(this.key)
}

export type MenuStoreState = {
    menu: MenuItem[]
}

// Create initial state
export const state = (): MenuStoreState => ({
    menu: [
        {
            label: 'Produzioni',
            icon: 'far fa-chart-line',
            visible: import.meta.env.VITE_PRODUCTIONS_ENABLED === 'true',
            items: [
                {
                    label: 'Planner',
                    key: '/pr/planner',
                    icon: 'far fa-calendar-alt',
                    command: goToKeyAsRoute,
                    visible: () => {
                        const currentUserStore = useCurrentUserStore()
                        return currentUserStore.HAS_PERMISSIONS([
                            'pr,get_binders_for_calendar',
                        ])
                    },
                },
                {
                    label: 'Aggiungi produzione',
                    key: '/pr/production/add',
                    command: goToKeyAsRoute,
                    icon: 'far fa-calendar-plus',
                    visible: () => {
                        const currentUserStore = useCurrentUserStore()
                        return currentUserStore.HAS_PERMISSIONS([
                            'pr,add_production',
                        ])
                    },
                },
            ],
        },
        {
            label: 'Magazzino',
            icon: 'far fa-warehouse',
            visible: import.meta.env.VITE_WAREHOUSE_ENABLED === 'true',
            items: [
                {
                    label: 'Schede prodotto',
                    key: '/wh/product-sheets',
                    command: goToKeyAsRoute,
                    icon: 'far fa-boxes',
                    visible: () => {
                        const currentUserStore = useCurrentUserStore()
                        return currentUserStore.HAS_PERMISSIONS([
                            'wh,get_cats_tree',
                        ])
                    },
                },
            ],
        },
        {
            label: 'Tecnici',
            icon: 'far fa-chart-line',
            visible: import.meta.env.VITE_TECHNICIANS_ENABLED === 'true',
            items: [
                {
                    label: 'Elenco tecnici',
                    key: '/tc/list',
                    command: goToKeyAsRoute,
                    icon: 'far fa-user-hard-hat',
                    visible: () => {
                        const currentUserStore = useCurrentUserStore()
                        return currentUserStore.HAS_PERMISSIONS([
                            'tc,filter_tc_by_role',
                        ])
                    },
                },
                {
                    label: 'Planner',
                    key: '/tc/planner',
                    command: goToKeyAsRoute,
                    icon: 'far fa-calendar-alt',
                    visible: false,
                    // visible: () => {
                    //     const currentUserStore = useCurrentUserStore()
                    //     return currentUserStore.HAS_PERMISSIONS([
                    //         'pr,get_all_tech_reservations_for_calendar',
                    //     ])
                    // },
                },
                {
                    label: 'Gestione competenze',
                    icon: 'far fa-toolbox',
                    key: '/tc/roles',
                    command: goToKeyAsRoute,
                    visible: false,
                    // visible: () => {
                    //     const currentUserStore = useCurrentUserStore()
                    //     return currentUserStore.HAS_PERMISSIONS([
                    //         'tc,get_areas_tree',
                    //     ])
                    // },
                },
            ],
        },
        {
            label: 'Gestione aziendale',
            icon: 'far fa-receipt',
            visible: () => {
                const currentUserStore = useCurrentUserStore()
                return currentUserStore.HAS_PERMISSIONS([
                    'cm,get_passive_invoices',
                ])
            },
            items: [
                {
                    label: 'Riassunto',
                    icon: 'far fa-chart-line',
                    key: '/company-management/summary',
                    command: goToKeyAsRoute,
                    visible: () => {
                        const currentUserStore = useCurrentUserStore()
                        return currentUserStore.HAS_PERMISSIONS([
                            'cm,get_summary',
                        ])
                    },
                },
                {
                    label: 'Importa fatture',
                    icon: 'far fa-file-import',
                    items: [
                        {
                            label: 'Upload fatture passive',
                            icon: 'far fa-file-upload',
                            key: '/company-management/passive-invoices-upload',
                            command: goToKeyAsRoute,
                            visible: () => {
                                const currentUserStore = useCurrentUserStore()
                                return currentUserStore.HAS_PERMISSIONS([
                                    'cm,add_passive_invoices',
                                ])
                            },
                        },
                        {
                            label: 'Aggiungi spesa',
                            icon: 'far fa-file-plus',
                            key: '/company-management/cost-add',
                            command: goToKeyAsRoute,
                            visible: () => {
                                const currentUserStore = useCurrentUserStore()
                                return currentUserStore.HAS_PERMISSIONS([
                                    'cm,add_cost',
                                ])
                            },
                        },
                        {
                            label: 'Aggiungi entrata',
                            icon: 'far fa-file-plus',
                            key: '/company-management/taking-add',
                            command: goToKeyAsRoute,
                            visible: () => {
                                const currentUserStore = useCurrentUserStore()
                                return currentUserStore.HAS_PERMISSIONS([
                                    'cm,add_cost',
                                ])
                            },
                        },
                        {
                            label: 'Aggiungi fattura extra SDI',
                            icon: 'far fa-file-plus',
                            key: '/company-management/invoice-add',
                            command: goToKeyAsRoute,
                            visible: () => {
                                const currentUserStore = useCurrentUserStore()
                                return currentUserStore.HAS_PERMISSIONS([
                                    'cm,add_passive_invoice_manual',
                                ])
                            },
                        },
                        {
                            label: 'Aggiungi fattura estera',
                            icon: 'far fa-file-plus',
                            key: '/company-management/foreign-invoice-add',
                            command: goToKeyAsRoute,
                            visible: () => {
                                const currentUserStore = useCurrentUserStore()
                                return currentUserStore.HAS_PERMISSIONS([
                                    'cm,add_passive_invoice_manual',
                                ])
                            },
                        },
                        {
                            label: 'Upload fatture attive',
                            icon: 'far fa-file-upload',
                            key: '/company-management/active-invoices-upload',
                            command: goToKeyAsRoute,
                            visible: () => {
                                const currentUserStore = useCurrentUserStore()
                                return currentUserStore.HAS_PERMISSIONS([
                                    'cm,add_passive_invoices',
                                ])
                            },
                        },
                    ],
                },
                {
                    label: 'Gestione aziendale',
                    icon: 'far fa-analytics',
                    items: [
                        {
                            label: 'Scadenziario',
                            icon: 'far fa-alarm-clock',
                            key: '/company-management/payments-schedule',
                            command: goToKeyAsRoute,
                            visible: () => {
                                const currentUserStore = useCurrentUserStore()
                                return currentUserStore.HAS_PERMISSIONS([
                                    'cm,get_costs',
                                ])
                            },
                        },
                        {
                            label: 'Incassi',
                            icon: 'far fa-coins',
                            key: '/company-management/takings-schedule',
                            command: goToKeyAsRoute,
                            visible: () => {
                                const currentUserStore = useCurrentUserStore()
                                return currentUserStore.HAS_PERMISSIONS([
                                    'cm,get_costs',
                                ])
                            },
                        },
                        {
                            label: 'Stime',
                            icon: 'far fa-abacus',
                            key: '/company-management/payments-predictions',
                            command: goToKeyAsRoute,
                            visible: () => {
                                const currentUserStore = useCurrentUserStore()
                                return currentUserStore.HAS_PERMISSIONS([
                                    'cm,get_costs',
                                ])
                            },
                        },
                        {
                            label: 'Flusso di cassa',
                            icon: 'far fa-chart-line',
                            key: '/company-management/cash-flow',
                            command: goToKeyAsRoute,
                            visible: () => {
                                const currentUserStore = useCurrentUserStore()
                                return currentUserStore.HAS_PERMISSIONS([
                                    'cm,get_schedule_totals_by_month',
                                ])
                            },
                        },
                        {
                            label: 'Fatture passive',
                            icon: 'far fa-file-invoice',
                            key: '/company-management/passive-invoices',
                            command: goToKeyAsRoute,
                            visible: () => {
                                const currentUserStore = useCurrentUserStore()
                                return currentUserStore.HAS_PERMISSIONS([
                                    'cm,get_passive_invoice',
                                ])
                            },
                        },
                        {
                            label: 'Fatture estere',
                            icon: 'far fa-file-invoice',
                            key: '/company-management/foreign-invoices',
                            command: goToKeyAsRoute,
                            visible: () => {
                                const currentUserStore = useCurrentUserStore()
                                return currentUserStore.HAS_PERMISSIONS([
                                    'cm,get_passive_invoice',
                                ])
                            },
                        },
                        {
                            label: 'Fatture attive',
                            icon: 'far fa-file-invoice',
                            key: '/company-management/active-invoices',
                            command: goToKeyAsRoute,
                            visible: () => {
                                const currentUserStore = useCurrentUserStore()
                                return currentUserStore.HAS_PERMISSIONS([
                                    'cm,get_passive_invoice',
                                ])
                            },
                        },
                        {
                            label: 'FTT importate',
                            icon: 'far fa-file-invoice',
                            key: '/company-management/passive-invoices-not-verified',
                            command: goToKeyAsRoute,
                            visible: () => {
                                const currentUserStore = useCurrentUserStore()
                                return currentUserStore.HAS_PERMISSIONS([
                                    'cm,get_passive_invoice',
                                ])
                            },
                        },
                        {
                            label: 'FTT acquisti da verificare',
                            icon: 'far fa-file-invoice',
                            key: '/company-management/passive-invoices-assets-not-verified',
                            command: goToKeyAsRoute,
                            visible: () => {
                                const currentUserStore = useCurrentUserStore()
                                return currentUserStore.HAS_PERMISSIONS([
                                    'cm,get_passive_invoice',
                                ])
                            },
                        },
                        {
                            label: 'FTT da verificare Sandro',
                            icon: 'far fa-file-invoice',
                            key: '/company-management/passive-invoices-assets-not-verified-sandro',
                            command: goToKeyAsRoute,
                            visible: () => {
                                const currentUserStore = useCurrentUserStore()
                                return currentUserStore.HAS_PERMISSIONS([
                                    'cm,get_passive_invoice',
                                ])
                            },
                        },
                    ],
                },
                {
                    label: 'Export fatture',
                    icon: 'far fa-file-export',
                    items: [
                        {
                            label: 'Spese',
                            icon: 'far fa-shopping-cart',
                            key: '/company-management/costs-search',
                            command: goToKeyAsRoute,
                            visible: () => {
                                const currentUserStore = useCurrentUserStore()
                                return currentUserStore.HAS_PERMISSIONS([
                                    'cm,get_costs',
                                ])
                            },
                        },
                        {
                            label: 'Incassi',
                            icon: 'far fa-coins',
                            key: '/company-management/takings-search',
                            command: goToKeyAsRoute,
                            visible: () => {
                                const currentUserStore = useCurrentUserStore()
                                return currentUserStore.HAS_PERMISSIONS([
                                    'cm,get_costs',
                                ])
                            },
                        },
                        {
                            label: 'Fatture SDI',
                            icon: 'far fa-file-invoice',
                            key: '/company-management/passive-invoices-search/sdi',
                            command: goToKeyAsRoute,
                            visible: () => {
                                const currentUserStore = useCurrentUserStore()
                                return currentUserStore.HAS_PERMISSIONS([
                                    'cm,get_passive_invoices',
                                ])
                            },
                        },
                        {
                            label: 'Fatture extra SDI',
                            icon: 'far fa-file-invoice',
                            key: '/company-management/passive-invoices-search/ext',
                            command: goToKeyAsRoute,
                            visible: () => {
                                const currentUserStore = useCurrentUserStore()
                                return currentUserStore.HAS_PERMISSIONS([
                                    'cm,get_passive_invoices',
                                ])
                            },
                        },
                        {
                            label: 'Fatture estere',
                            icon: 'far fa-file-invoice',
                            key: '/company-management/passive-invoices-search/foreign',
                            command: goToKeyAsRoute,
                            visible: () => {
                                const currentUserStore = useCurrentUserStore()
                                return currentUserStore.HAS_PERMISSIONS([
                                    'cm,get_passive_invoices',
                                ])
                            },
                        },
                    ],
                },
            ],
        },
        {
            label: 'Rubrica',
            icon: 'far fa-address-book',
            key: '/ab/index',
            command: goToKeyAsRoute,
            visible: () => {
                const currentUserStore = useCurrentUserStore()
                return currentUserStore.HAS_PERMISSIONS(['ab,get_letter'])
            },
            items: [
                {
                    label: 'Schede rubrica',
                    icon: 'far fa-address-book',
                    key: '/ab/index',
                    command: goToKeyAsRoute,
                    visible: () => {
                        const currentUserStore = useCurrentUserStore()
                        return currentUserStore.HAS_PERMISSIONS([
                            'ab,get_letter',
                        ])
                    },
                },
            ],
        },
        {
            label: 'Sviluppo',
            icon: 'far fa-fire',
            key: '/dev/application-log',
            command: goToKeyAsRoute,
            visible: () => {
                const currentUserStore = useCurrentUserStore()
                return currentUserStore.HAS_PERMISSIONS(['lg,get_log_app'])
            },
            items: [
                {
                    label: 'Log applicazione',
                    icon: 'far fa-fire',
                    key: '/dev/application-log',
                    command: goToKeyAsRoute,
                    visible: () => {
                        const currentUserStore = useCurrentUserStore()
                        return currentUserStore.HAS_PERMISSIONS([
                            'lg,get_log_app',
                        ])
                    },
                },
            ],
        },
    ],
})
