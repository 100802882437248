<script setup>
import AppMenu from './AppMenu.vue'
import { useRouter } from 'vue-router'
import Button from 'primevue/button'
import { useUiStore } from '@/modules/primevue/stores/ui-store'
import { computed } from 'vue'

const uiStore = useUiStore()
const router = useRouter()

let timeout = null
const colorScheme = computed(() => uiStore.colorScheme)

const onMouseEnter = () => {
    if (!uiStore.anchored) {
        if (timeout) {
            clearTimeout(timeout)
            timeout = null
        }
        uiStore.sidebarActive = true
    }
}

const onMouseLeave = () => {
    if (!uiStore.anchored) {
        if (!timeout) {
            timeout = setTimeout(() => (uiStore.sidebarActive = false), 300)
        }
    }
}

const anchor = () => {
    uiStore.anchored = !uiStore.anchored
}
const navigateToHome = () => {
    router.push('/')
}

const logoIconSrc = computed(() =>
    uiStore.colorScheme === 'light'
        ? import.meta.env.BASE_URL + 'img/brand/logo-icon-light.svg'
        : import.meta.env.BASE_URL + 'img/brand/logo-icon-dark.svg',
)
const logoSrc = computed(() =>
    uiStore.colorScheme === 'light'
        ? import.meta.env.BASE_URL + 'img/brand/logo-light.svg'
        : import.meta.env.BASE_URL + 'img/brand/logo-dark.svg',
)

const helpdeskUrl = import.meta.env.VITE_HELPDESK_URL
</script>

<template>
    <div
        class="layout-sidebar"
        @mouseenter="onMouseEnter()"
        @mouseleave="onMouseLeave()"
    >
        <div class="sidebar-header">
            <a @click="navigateToHome" class="app-logo" style="cursor: pointer">
                <div class="app-logo-small h-2rem">
                    <img :src="logoIconSrc" alt="logo" role="presentation" />
                </div>
                <div class="app-logo-normal">
                    <img
                        class="h-3rem"
                        :src="logoSrc"
                        alt="logo"
                        role="presentation"
                    />
                </div>
            </a>
            <Button
                class="layout-sidebar-anchor p-link z-2"
                type="button"
                @click="anchor()"
            ></Button>
        </div>

        <div class="layout-menu-container flex flex-column">
            <AppMenu class="flex-grow-1" />
            <div class="helpdesk-menu-item" v-if="helpdeskUrl">
                <a
                    :href="helpdeskUrl"
                    target="_blank"
                    class="flex text-color-info"
                    ><span class="flex-grow-1">{{
                        $t('SLS.helpdesk_action')
                    }}</span
                    ><i class="far fa-question"></i
                ></a>
            </div>
        </div>
    </div>
</template>
