import { SLSApiRequest } from '@/modules/sls/models'

export const requestSelectors = {
    GET: { sec: 'usr', req: 'status' },
    LOGIN: { sec: 'usr', req: 'login' },
    LOGOUT: { sec: 'usr', req: 'logout' },

    GET_USERS: {
        sec: 'usr',
        req: 'get_users',
    },

    GET_ACTIVE_SUPERVISOR_USERS: {
        sec: 'usr',
        req: 'get_active_supervisor_users',
    },
} as { [key: string]: SLSApiRequest }
