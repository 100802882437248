// Declare state
import { CurrentUser } from '@/modules/user/models'
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js'

export type CurrentUserStoreState = {
    currentUser: CurrentUser | null
    cognitoUser: CognitoUser | null
    cognitoSession: CognitoUserSession | null
    cognitoStatus: any
}

// Create initial state
export const state = (): CurrentUserStoreState => ({
    currentUser: null,
    cognitoUser: null,
    cognitoSession: null,
    cognitoStatus: null,
})
