// Declare state
import { ToastServiceMethods } from 'primevue/toastservice'

export type UiStoreState = {
    // PrimeVue
    staticMenuDesktopInactive: boolean
    overlayMenuActive: boolean
    rightMenuActive: boolean
    staticMenuMobileActive: boolean
    menuHoverActive: boolean
    sidebarActive: boolean
    anchored: boolean
    activeMenuItem: null
    overlaySubmenuActive: boolean
    rightMenuVisible: boolean

    // Tema
    colorScheme: string

    // Localization
    locale: string
    locale_full: string
    currency: string

    // SLS Big loader
    isLoading: boolean
    showBigLoader: boolean

    // Error handling
    currentError: any | null

    // Toast
    toastService: ToastServiceMethods | null

    // Dipendenti dal modulo
    productionProductReservationsExtraColumnsShown: string[]
}

// Create initial state
export const state = (): UiStoreState => ({
    staticMenuDesktopInactive: false,
    overlayMenuActive: false,
    rightMenuActive: false,
    staticMenuMobileActive: false,
    menuHoverActive: false,
    sidebarActive: true,
    anchored: true,
    activeMenuItem: null,
    overlaySubmenuActive: false,
    rightMenuVisible: false,

    colorScheme: import.meta.env.VITE_DEFAULT_COLOR_SCHEME,

    locale: 'it',
    locale_full: 'it-IT',
    currency: 'EUR',

    isLoading: false,
    showBigLoader: true,

    currentError: null,
    toastService: null,

    productionProductReservationsExtraColumnsShown: ['note', 'daily_price'],
})
