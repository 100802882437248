import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { AddressBookRouter } from '@/modules/address-book/router'
import { CompanyManagementRouter } from '@/modules/company-management/router'
import { DevRouter } from '@/modules/dev/router'
import { ProductionsRouter } from '@/modules/production/router'
import { TechniciansRouter } from '@/modules/technician/router'
import { UsersRouter } from '@/modules/user/router'
import { WarehouseRouter } from '@/modules/warehouse/router'
import { setRouteTitle } from '@/modules/primevue/helpers'
import { useUiStore } from '@/modules/primevue/stores/ui-store'
import { useCurrentUserStore } from '@/modules/user/stores/current-user-store'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: () => {
            const userStore = useCurrentUserStore()
            return (
                userStore.currentUser?.config.basePath ||
                import.meta.env.VITE_DEFAULT_HOME_PATH
            )
        },
    },
    ...AddressBookRouter,
    ...CompanyManagementRouter,
    ...DevRouter,
    ...ProductionsRouter,
    ...TechniciansRouter,
    ...UsersRouter,
    ...WarehouseRouter,
]

const router = createRouter({
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) return savedPosition
        else if (to.hash) return { el: to.hash }
        else return { top: 0 }
    },
    history: createWebHashHistory(),
    routes,
})

let requestedRoute: string | null = null
router.beforeEach((to, from, next) => {
    const currentUserStore = useCurrentUserStore()
    const isAuthenticated = currentUserStore.IS_AUTHENTICATED

    const uiStore = useUiStore()

    if (to.meta && to.meta.title) {
        setRouteTitle(to.meta.title as string, true)
    }
    // TODO Testare se mancato reset titolo crea danni
    // else {
    //     documentTitleReset()
    // }

    // Saves destination route as last valid route
    // TODO authorization
    if (to.name !== 'Login') {
        requestedRoute = to.path
    }

    if (to.name === 'Login' && isAuthenticated) {
        console.debug('Already authenticated, redirect')

        next(
            requestedRoute ||
                currentUserStore.currentUser?.config.basePath ||
                import.meta.env.VITE_DEFAULT_HOME_PATH,
        )
    } else if (to.matched.some((record) => record.meta.noAuth)) {
        uiStore.showBigLoader = false
        next()
    } else {
        if (isAuthenticated) {
            uiStore.showBigLoader = false
            next()
        } else {
            currentUserStore
                .FETCH_USER_DATA()
                .then(() => {
                    if (currentUserStore.IS_AUTHENTICATED) {
                        next()
                    } else {
                        next({ name: 'Login' })
                    }
                })
                .finally(() => {
                    uiStore.showBigLoader = false
                })
        }
    }
})

export const setLastRequestedRoute = (route: string) => {
    requestedRoute = route
}

export const getLastRequestedRoute = () => requestedRoute

export default router
