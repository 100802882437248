// Components
import AppLayout from '@/modules/primevue/components/AppLayout.vue'
import { RouteRecordRaw } from 'vue-router'
import { removeTrailingSlash } from '@/modules/primevue/helpers/string-manipulation'

const basePath = '/tc'

let routes: Array<RouteRecordRaw>

if (import.meta.env.VITE_TECHNICIANS_ENABLED === 'true') {
    routes = [
        {
            path: basePath,
            name: 'Tecnici',
            redirect: `${basePath}/list`,
            component: AppLayout,
            children: [
                {
                    path: `${basePath}/list`,
                    name: 'Elenco tecnici',
                    meta: {
                        title: 'Elenco tecnici',
                    },
                    component: () =>
                        import(
                            '@/modules/technician/components/TechniciansIndex.vue'
                        ),
                },
                {
                    path: `${basePath}/:id`,
                    name: 'Scheda tecnico',
                    meta: {
                        // title: '◌',
                    },
                    redirect: (to) =>
                        removeTrailingSlash(to.fullPath) + '/general',
                    component: () =>
                        import(
                            '@/modules/technician/components/TechnicianSheet.vue'
                        ),
                    children: [
                        {
                            path: 'general',
                            name: 'Scheda tecnico generale',
                            component: () =>
                                import(
                                    '@/modules/technician/components/TechnicianSheetAddressBook.vue'
                                ),
                        },
                        {
                            path: 'planner',
                            name: 'Scheda tecnico planner',
                            component: () =>
                                import(
                                    '@/modules/technician/components/TechnicianSheetPlanner.vue'
                                ),
                        },
                        {
                            path: 'technician-data',
                            name: 'Scheda tecnico dati tecnico',
                            component: () =>
                                import(
                                    '@/modules/technician/components/TechnicianSheetGeneral.vue'
                                ),
                        },
                        {
                            path: 'working-report',
                            name: 'Scheda tecnico resoconto mensile',
                            component: () =>
                                import(
                                    '@/modules/technician/components/TechnicianSheetWorkingReport.vue'
                                ),
                        },
                        {
                            path: 'price-list',
                            name: 'Scheda tecnico competenze e compensi correnti',
                            component: () =>
                                import(
                                    '@/modules/technician/components/TechnicianSheetPriceList.vue'
                                ),
                        },
                        {
                            path: 'manage-price-list',
                            name: 'Scheda tecnico gestione competenze e compensi correnti',
                            component: () =>
                                import(
                                    '@/modules/technician/components/TechnicianSheetPriceManager.vue'
                                ),
                        },
                        {
                            path: 'attachments',
                            name: 'Scheda tecnico allegati',
                            component: () =>
                                import(
                                    '@/modules/technician/components/TechnicianSheetAttachments.vue'
                                ),
                        },
                    ],
                },
            ],
        },
    ]
} else {
    routes = [
        {
            path: `${basePath}/:sub?`,
            redirect: '/ab/index',
            component: AppLayout,
            children: [],
        },
    ]
}

export const TechniciansRouter: Array<RouteRecordRaw> = routes
