// Components
import AppLayout from '@/modules/primevue/components/AppLayout.vue'
import { RouteRecordRaw } from 'vue-router'
import { removeTrailingSlash } from '@/modules/primevue/helpers/string-manipulation'

const basePath = '/wh'

let routes: Array<RouteRecordRaw>

if (import.meta.env.VITE_WAREHOUSE_ENABLED === 'true') {
    routes = [
        {
            path: basePath,
            name: 'Magazzino',
            redirect: `${basePath}/product-sheets`,
            component: AppLayout,
            children: [
                {
                    path: `${basePath}/product-sheets`,
                    name: 'Elenco schede prodotto',
                    meta: {
                        title: 'Schede prodotto',
                    },
                    component: () =>
                        import(
                            '@/modules/warehouse/components/ProductSheetsIndex.vue'
                        ),
                },
                {
                    path: `${basePath}/product-sheet/:id`,
                    name: 'Scheda prodotto',
                    meta: {
                        // title: '◌',
                    },
                    redirect: (to) =>
                        removeTrailingSlash(to.fullPath) + '/general',
                    component: () =>
                        import(
                            '@/modules/warehouse/components/ProductSheet.vue'
                        ),
                    children: [
                        {
                            path: 'general',
                            name: 'Scheda prodotto generale',
                            component: () =>
                                import(
                                    '@/modules/warehouse/components/ProductSheetGeneral.vue'
                                ),
                        },
                        {
                            path: 'economic-data',
                            name: 'Scheda prodotto dati economici',
                            component: () =>
                                import(
                                    '@/modules/warehouse/components/ProductSheetEconomicData.vue'
                                ),
                        },
                        {
                            path: 'details-ordinary',
                            name: 'Scheda prodotto dettagli p. ordinario',
                            component: () =>
                                import(
                                    '@/modules/warehouse/components/ProductSheetDetailOrdinary.vue'
                                ),
                        },
                        {
                            path: 'details-composite',
                            name: 'Scheda prodotto dettagli p. composto',
                            component: () =>
                                import(
                                    '@/modules/warehouse/components/ProductSheetDetailComposite.vue'
                                ),
                        },
                        {
                            path: 'details-virtual',
                            name: 'Scheda prodotto dettagli p. virtuale',
                            component: () =>
                                import(
                                    '@/modules/warehouse/components/ProductSheetDetailVirtual.vue'
                                ),
                        },
                        {
                            path: 'attachments',
                            name: 'Scheda prodotto allegati',
                            component: () =>
                                import(
                                    '@/modules/warehouse/components/ProductSheetAttachments.vue'
                                ),
                        },
                    ],
                },
            ],
        },
    ]
} else {
    routes = [
        {
            path: `${basePath}/:sub?`,
            redirect: '/ab/index',
            component: AppLayout,
            children: [],
        },
    ]
}

export const WarehouseRouter: Array<RouteRecordRaw> = routes
