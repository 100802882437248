<script lang="ts">
import { computed, ref } from 'vue'
import AppSidebar from './AppSidebar.vue'
import Button from 'primevue/button'
import Avatar from 'primevue/avatar'
import SelectButton from 'primevue/selectbutton'
import { useCurrentUserStore } from '@/modules/user/stores/current-user-store'
import { useUiStore } from '@/modules/primevue/stores/ui-store'

export default {
    name: 'AppTopbar',
    components: {
        AppSidebar,
        Button,
        Avatar,
        SelectButton,
    },
    setup() {
        const uiStore = useUiStore()

        const searchInput = ref(null)
        const searchActive = ref(false)

        const onMenuButtonClick = () => {
            uiStore.ON_MENU_TOGGLE()
        }

        const onSidebarButtonClick = () => {
            uiStore.rightMenuVisible = true
        }

        const currentUserStore = useCurrentUserStore()

        const colorScheme = computed(() => uiStore.colorScheme)

        const options = ref([
            { icon: 'fa-sun', value: 'light', label: 'light theme' },
            { icon: 'fa-moon', value: 'dark', label: 'dark theme' },
        ])

        const changeColorScheme = (newColorScheme: any) => {
            uiStore.CHANGE_COLOR_SCHEME(newColorScheme.value)
        }

        const switchSelectorClass = (slotProps: any, colorScheme: string) => {
            const iconType =
                colorScheme === slotProps.option.value ? 'fas' : 'far'

            const iconColor =
                colorScheme === slotProps.option.value ? 'primary-color' : ''
            return `${iconType} ${slotProps.option.icon} ${iconColor}`
        }

        return {
            searchInput,
            searchActive,

            onMenuButtonClick,
            onSidebarButtonClick,
            switchSelectorClass,

            colorScheme,
            options,
            changeColorScheme,

            async logout() {
                await currentUserStore.LOGOUT()
            },
            initials: computed(() => {
                if (!currentUserStore.currentUser?.userId) {
                    return 'U'
                }
                const nameInitial = currentUserStore.currentUser.name.trim()
                    .length
                    ? currentUserStore.currentUser.name.trim().substring(0, 1)
                    : ''
                const surnameInitial =
                    currentUserStore.currentUser.surname.trim().length
                        ? currentUserStore.currentUser.surname
                              .trim()
                              .substring(0, 1)
                        : ''

                return nameInitial + surnameInitial
            }),
        }
    },
}
</script>
<template>
    <div id="topbar" class="layout-topbar">
        <div class="topbar-start">
            <Button
                ref="menubutton"
                type="button"
                class="topbar-menubutton p-link p-trigger"
                @click="onMenuButtonClick()"
            >
                <i class="far fa-bars"></i>
            </Button>
        </div>
        <div class="layout-topbar-menu-section">
            <AppSidebar />
        </div>
        <div class="topbar-end">
            <ul class="topbar-menu m-0 p-0">
                <li id="topbar-theme-switch" class="topbar-item mr-4">
                    <SelectButton
                        model-value="colorScheme"
                        @update:modelValue="changeColorScheme"
                        :options="options"
                        aria-labelledby="dark"
                        optionLabel="label"
                        dataKey="value"
                    >
                        <template #option="slotProps">
                            <i
                                :class="
                                    switchSelectorClass(slotProps, colorScheme)
                                "
                            ></i>
                        </template>
                    </SelectButton>
                </li>
                <li
                    ref="profile"
                    id="topbar-profile-item"
                    class="profile-item topbar-item m-0"
                >
                    <a
                        v-styleclass="{
                            selector: '@next',
                            enterClass: 'hidden',
                            enterActiveClass: 'px-scalein',
                            leaveToClass: 'hidden',
                            leaveActiveClass: 'px-fadeout',
                            hideOnOutsideClick: true,
                        }"
                        ripple
                        class="cursor-pointer"
                    >
                        <Avatar
                            :label="initials"
                            shape="circle"
                            size="large"
                        ></Avatar>
                    </a>

                    <ul
                        class="topbar-menu active-topbar-menu p-4 w-15rem z-5 hidden border-round"
                    >
                        <li role="menuitem" class="m-0">
                            <a
                                href="#"
                                @click="logout()"
                                class="flex align-items-center hover:text-primary-500 transition-duration-200"
                                v-styleclass="{
                                    selector: '@grandparent',
                                    enterClass: 'hidden',
                                    enterActiveClass: 'px-scalein',
                                    leaveToClass: 'hidden',
                                    leaveActiveClass: 'px-fadeout',
                                    hideOnOutsideClick: true,
                                }"
                            >
                                <i class="far fa-sign-out mr-1"></i>
                                <span>Logout</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>

<style lang="scss" scoped>
::v-deep(.layout-megamenu) {
    background: none;

    .p-megamenu-root-list {
        .p-menuitem-link:not(.p-submenu-list .p-menuitem-link) {
            .p-menuitem-text,
            .p-menuitem-icon,
            .p-submenu-icon {
                color: var(--topbar-item-text-color) !important;
            }

            .layout-megamenu:not(.p-disabled):hover {
                background: var(--topbar-item-hover-bg);
                transition: background-color var(--transition-duration);
            }
        }
    }
}
</style>
