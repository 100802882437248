import { createI18n } from 'vue-i18n'

import uiIt from '@/modules/primevue/locales/it-IT.json'
import uiEn from '@/modules/primevue/locales/en-US.json'

import addressBookIt from '@/modules/address-book/locales/it-IT.json'
import addressBookEn from '@/modules/address-book/locales/en-US.json'

import companyManagementIt from '@/modules/company-management/locales/it-IT.json'
import companyManagementEn from '@/modules/company-management/locales/en-US.json'

import devIt from '@/modules/dev/locales/it-IT.json'
import devEn from '@/modules/dev/locales/en-US.json'

import productionsIt from '@/modules/production/locales/it-IT.json'
import productionsEn from '@/modules/production/locales/en-US.json'

import slsIt from '@/modules/sls/locales/it-IT.json'
import slsEn from '@/modules/sls/locales/en-US.json'

import technicianIt from '@/modules/technician/locales/it-IT.json'
import technicianEn from '@/modules/technician/locales/en-US.json'

import usersIt from '@/modules/user/locales/it-IT.json'
import usersEn from '@/modules/user/locales/en-US.json'

import warehouseIt from '@/modules/warehouse/locales/it-IT.json'
import warehouseEn from '@/modules/warehouse/locales/en-US.json'

export type MessageSchema = typeof itIT

export const itIT = {
    ui: uiIt.it,
    ...addressBookIt,
    ...companyManagementIt,
    ...devIt,
    ...productionsIt,
    ...slsIt,
    ...technicianIt,
    ...usersIt,
    ...warehouseIt,
}
export const enUS = {
    ui: uiEn.en,
    ...addressBookEn,
    ...companyManagementEn,
    ...devEn,
    ...productionsEn,
    ...slsEn,
    ...technicianEn,
    ...usersEn,
    ...warehouseEn,
}

export const i18nPlugin = createI18n<[MessageSchema], 'it-IT' | 'en-US'>({
    locale: 'it-IT',
    fallbackLocale: 'it-IT',
    legacy: false,
    messages: {
        'it-IT': itIT,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        'en-US': enUS,
    },

    fallbackWarn: !import.meta.env.PROD,
    missingWarn: !import.meta.env.PROD,
})
