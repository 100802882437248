import { RouteRecordRaw } from 'vue-router'
import { removeTrailingSlash } from '@/modules/primevue/helpers/string-manipulation'

const basePath = '/pr'

export default {
    path: `${basePath}/production/:id`,
    name: 'Produzione',
    meta: {
        // title: '◌',
    },
    redirect: (to) => removeTrailingSlash(to.fullPath) + '/general',
    component: () =>
        import(
            '@/modules/production/components/production/ProductionSheet.vue'
        ),
    children: [
        {
            path: 'general',
            name: 'Scheda produzione generale',
            component: () =>
                import(
                    '@/modules/production/components/production/ProductionGeneral.vue'
                ),
        },
        {
            path: 'dates-management',
            name: 'Scheda gestione date',
            component: () =>
                import(
                    '@/modules/production/components/production/ProductionDatesManagement.vue'
                ),
        },
        {
            path: 'data-export',
            name: 'Scheda esportazione dati',
            component: () =>
                import(
                    '@/modules/production/components/production/ProductionDataExport.vue'
                ),
        },
        {
            path: 'products-list',
            name: 'Materiale a magazzino',
            component: () =>
                import(
                    '@/modules/production/components/production/ProductionProductReservations.vue'
                ),
        },
        {
            path: 'technicians-list',
            name: 'Prenotazioni tecnici in produzione',
            component: () =>
                import(
                    '@/modules/production/components/production/ProductionTechnicianReservations.vue'
                ),
        },
        {
            path: 'ddt',
            name: 'Scheda Documento di Trasporto',
            component: () =>
                import(
                    '@/modules/production/components/production/ProductionDDT.vue'
                ),
        },
        {
            path: 'notes',
            name: 'Note Produzione',
            component: () =>
                import(
                    '@/modules/production/components/production/ProductionNotes.vue'
                ),
        },
        {
            path: 'attachments',
            name: 'Allegati Produzione',
            component: () =>
                import(
                    '@/modules/production/components/production/ProductionAttachments.vue'
                ),
        },
        {
            path: 'ee/:entryTypeId',
            name: 'Preventivi esterni in produzione',
            component: () =>
                import(
                    '@/modules/production/components/ext-entry/ExtEntryProduction.vue'
                ),
        },
    ],
} as RouteRecordRaw
