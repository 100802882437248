import { defineStore } from 'pinia'
import { state } from './current-user-store-state'
import * as requestTypes from '@/modules/user/api/request-types'
import { axiosRequest } from '@/store/action-helpers'
import axios from 'axios/index'
import config from '@/config'
import { requestSelectors } from '@/modules/user/api/request-selectors'
import router, { getLastRequestedRoute, setLastRequestedRoute } from '@/router'
import { CurrentUser } from '@/modules/user/models'
import { useUiStore } from '@/modules/primevue/stores/ui-store'
import getCognitoUserPool, {
    cognitoAuthenticationCallback,
    setAuthorizationHeader,
    startTokenRefreshHandler,
} from '@/modules/user/helpers/get-cognito-user-pool'
import {
    AuthenticationDetails,
    CognitoRefreshToken,
    CognitoUser,
    CognitoUserSession,
} from 'amazon-cognito-identity-js'
import { SLSApiRequest } from '@/modules/sls/models'

export const useCurrentUserStore = defineStore('currentUser', {
    state,

    getters: {
        IS_AUTHENTICATED(): boolean {
            switch (import.meta.env.VITE_LOGIN_PROVIDER) {
                case 'cognito':
                    return this.cognitoSession !== null
                default:
                    return !!this.currentUser?.userId
            }
        },
        HAS_PERMISSION() {
            return (
                permission: string | SLSApiRequest,
                checkEconomicData = false,
            ): boolean => {
                const key =
                    typeof permission === 'string'
                        ? permission
                        : `${permission.sec},${permission.req}`

                return (
                    this.currentUser !== null &&
                    this.currentUser.perms[key] &&
                    (!checkEconomicData ||
                        this.currentUser.perms[key].economic_data_allowed)
                )
            }
        },

        HAS_NOT_PERMISSION() {
            return (
                permission: string | SLSApiRequest,
                checkEconomicData = false,
            ): boolean => !this.HAS_PERMISSION(permission, checkEconomicData)
        },

        HAS_PERMISSIONS() {
            return (
                permissions: (string | SLSApiRequest)[],
                checkEconomicData = false,
            ): boolean =>
                permissions.reduce(
                    (acc: boolean, item: string | SLSApiRequest) =>
                        acc && this.HAS_PERMISSION(item, checkEconomicData),
                    true,
                )
        },
    },

    actions: {
        async GET_COGNITO_SESSION() {
            const currentUser = getCognitoUserPool().getCurrentUser()
            if (currentUser) {
                document.cookie =
                    'PHPSESSID=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'

                return new Promise<void>((resolve, reject) => {
                    currentUser.getSession(
                        (error: null, session: CognitoUserSession | null) => {
                            if (error) {
                                this.cognitoSession = null
                                reject(error)
                            } else {
                                this.cognitoSession = session

                                setAuthorizationHeader()
                                startTokenRefreshHandler()

                                resolve()
                            }
                        },
                    )
                })
            } else {
                this.cognitoSession = null
                this.currentUser = null
            }
        },
        async COMPLETE_USER_PROFILE(data: any) {
            console.debug('COMPLETE_USER_PROFILE', data)

            const password = data.pwd
            delete data.pwd
            delete data.pwd_confirm
            delete data.email
            delete data.email_verified

            return new Promise<void>((resolve, reject) => {
                this.cognitoUser?.completeNewPasswordChallenge(
                    password,
                    data,
                    cognitoAuthenticationCallback(resolve, reject),
                )
            })
        },
        async REFRESH_COGNITO_SESSION() {
            console.debug('REFRESH_COGNITO_SESSION')

            return new Promise<void>((resolve, reject) => {
                if (this.cognitoUser) {
                    console.debug(
                        'REFRESH_COGNITO_SESSION cognitoUser',
                        this.cognitoUser,
                    )
                    console.debug(
                        'REFRESH_COGNITO_SESSION cognitoSession',
                        this.cognitoSession,
                    )

                    this.cognitoUser.refreshSession(
                        this.cognitoSession?.getRefreshToken() as CognitoRefreshToken,
                        (err, data) => {
                            if (err) {
                                this.LOGOUT()
                                reject(err)
                            } else {
                                this.cognitoSession = data

                                setAuthorizationHeader()
                                startTokenRefreshHandler()

                                resolve()
                            }
                        },
                    )
                }
            })
        },

        async LOGIN(payload: requestTypes.LoginData) {
            const uiStore = useUiStore()

            switch (import.meta.env.VITE_LOGIN_PROVIDER) {
                case 'cognito':
                    this.cognitoUser = new CognitoUser({
                        Username: payload.user,
                        Pool: getCognitoUserPool(),
                    })
                    await new Promise<void>((resolve, reject) => {
                        this.cognitoUser?.authenticateUser(
                            new AuthenticationDetails({
                                Username: payload.user,
                                Password: payload.pwd,
                            }),
                            cognitoAuthenticationCallback(resolve, reject),
                        )
                    })

                    await this.FETCH_USER_DATA()

                    await router.replace(
                        getLastRequestedRoute() ||
                            this.currentUser?.config?.basePath ||
                            import.meta.env.VITE_DEFAULT_HOME_PATH,
                    )
                    break

                default:
                    return axiosRequest(
                        async () => {
                            const response = await axios.post(
                                config().ENDPOINT,
                                {
                                    ...requestSelectors.LOGIN,
                                    ...payload,
                                },
                            )

                            this.currentUser = response.data as CurrentUser
                        },
                        async () => {
                            uiStore.CLEAR_TOASTS()

                            await router.replace(
                                getLastRequestedRoute() ||
                                    this.currentUser?.config?.basePath ||
                                    import.meta.env.VITE_DEFAULT_HOME_PATH,
                            )
                        },
                        () => {
                            uiStore.ADD_TOAST({
                                severity: 'warn',
                                detail: 'Credenziali errate',
                                life: 3000,
                            })
                        },
                    )
            }
        },
        async LOGOUT() {
            const uiStore = useUiStore()

            switch (import.meta.env.VITE_LOGIN_PROVIDER) {
                case 'cognito':
                    this.cognitoUser = null
                    this.cognitoSession = null

                    return new Promise<void>((resolve) => {
                        getCognitoUserPool()
                            .getCurrentUser()
                            ?.signOut(() => resolve())
                    })

                default:
                    await axiosRequest(
                        async () =>
                            axios.post(config().ENDPOINT, {
                                ...requestSelectors.LOGOUT,
                            }),
                        async () => {
                            uiStore.CLEAR_TOASTS()

                            this.currentUser = null

                            await router.push('/login')
                        },
                    )
            }
        },
        async FETCH_USER_DATA() {
            try {
                const response = await axios.get(config().ENDPOINT, {
                    params: requestSelectors.GET,
                })

                this.currentUser = response.data as CurrentUser

                if (
                    !this.currentUser ||
                    this.currentUser.userId !== response.data.userId
                ) {
                    // Still the same user
                    await router.push({
                        path: response.data.config.basePath,
                    })
                } else if (router.currentRoute.value.name === 'Login') {
                    // Current route is login, go to valid route
                    await router.replace(
                        getLastRequestedRoute() ||
                            this.currentUser.config.basePath,
                    )
                }
            } catch (e: any) {
                // Pulisco utente corrente
                this.currentUser = null
                this.cognitoSession = null
                this.cognitoUser = null

                // Errore non identificato
                const responseStatus = e?.response?.status

                // Errore non identificato
                if (!responseStatus) throw e

                // Se non autenticato ma in una rotta, navigo al login salvando la rotta corrente
                if (router.currentRoute.value.name !== 'Login') {
                    setLastRequestedRoute(router.currentRoute.value.path)

                    await router.push({ name: 'Login' })
                }
            }
        },
    },
})
