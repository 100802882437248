import { createApp, watch } from 'vue'
import App from './App.vue'
import router from './router'

import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import Tooltip from 'primevue/tooltip'

import axios from 'axios'
axios.defaults.withCredentials = true

import { i18nPlugin } from '@/locales'

import pinia from '@/store'

import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import StyleClass from 'primevue/styleclass'
import Ripple from 'primevue/ripple'
//import { defineRule } from 'vee-validate'
//import helpers from './plugins/helpers'

const app = createApp(App)

if (import.meta.env.PROD) {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracePropagationTargets: [
                    'madeinshow.app',
                    'synclab.services',
                    /^\//,
                ],
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    })
}

app.use(pinia)
app.use(router)
app.use(i18nPlugin)
app.use(PrimeVue, { ripple: true })
app.use(ToastService)
//app.use(helpers)
app.directive('tooltip', Tooltip)
app.directive('ripple', Ripple)
app.directive('styleclass', StyleClass)

// defineRule('required', function (value: string | any) {
//     if (!value || !value.length) {
//         return 'This field is required'
//     }
//     return true
// })

// Abilita o disabilita console.debug
const urlParams = new URLSearchParams(window.location.search)

if (!urlParams.has('debug') && !import.meta.env.DEV) {
    console.debug = () => {
        //
    }
}

app.mount('#app')
