// Components
import { RouteRecordRaw } from 'vue-router'

export const UsersRouter: Array<RouteRecordRaw> = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/modules/user/components/AppLogin.vue'),
        meta: {
            title: 'Login',
            noAuth: true,
        },
    },
    {
        path: '/complete-user-profile',
        name: 'Cambia password',
        component: () =>
            import('@/modules/user/components/AppCompleteUserProfile.vue'),
        meta: {
            title: 'Completa dati utente',
            noAuth: true,
        },
    },
]
