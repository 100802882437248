<script setup>
import { ref, onBeforeMount, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useUiStore } from '@/modules/primevue/stores/ui-store'
const route = useRoute()

const uiStore = useUiStore()

const props = defineProps({
    item: {
        type: Object,
        default: () => ({}),
    },
    index: {
        type: Number,
        default: 0,
    },
    root: {
        type: Boolean,
        default: true,
    },
    parentItemKey: {
        type: String,
        default: null,
    },
    rootIndex: {
        type: Number,
        default: 0,
    },
})

const isActiveMenu = ref(false)
const itemKey = ref(null)
const subMenuRef = ref(null)
const menuItemRef = ref(null)

onBeforeMount(() => {
    itemKey.value = props.parentItemKey
        ? props.parentItemKey + '-' + props.index
        : String(props.index)

    const activeItem = uiStore.activeMenuItem

    isActiveMenu.value =
        activeItem === itemKey.value || activeItem
            ? activeItem.startsWith(itemKey.value + '-')
            : false
})

watch(
    () => uiStore.activeMenuItem,
    (newVal) => {
        isActiveMenu.value =
            newVal === itemKey.value || newVal.startsWith(itemKey.value + '-')
    },
)

watch(
    () => uiStore.overlaySubmenuActive,
    (newValue) => {
        if (!newValue) {
            isActiveMenu.value = false
        }
    },
)
watch(
    () => route.path,
    (newPath) => {
        if (props.item.key && props.item.key === newPath) {
            uiStore.SET_ACTIVE_MENU_ITEM(itemKey)
        }
    },
)
const itemClick = async (event, item) => {
    if (item.disabled) {
        event.preventDefault()
        return
    }

    if (
        (item.key || item.url) &&
        (uiStore.staticMenuMobileActive || uiStore.overlayMenuActive)
    ) {
        uiStore.ON_MENU_TOGGLE()
    }

    if (item.command) {
        item.command({ originalEvent: event, item: item })
    }

    if (item.items) {
        uiStore.SET_ACTIVE_MENU_ITEM(
            isActiveMenu.value ? props.parentItemKey : itemKey,
        )
    } else {
        if (!uiStore.IS_DESKTOP) {
            uiStore.staticMenuMobileActive = !uiStore.staticMenuMobileActive
        }

        uiStore.SET_ACTIVE_MENU_ITEM(itemKey)
    }
}

const checkActiveRoute = (item) => {
    return route.path === item.key
}
</script>

<template>
    <li
        ref="menuItemRef"
        :class="{
            'layout-root-menuitem': root,
            'active-menuitem': !root && isActiveMenu,
        }"
    >
        <div
            v-if="
                root &&
                item.visible !== false &&
                (typeof item.visible !== 'function' || item.visible())
            "
            class="layout-menuitem-root-text"
        >
            {{ item.label }}
        </div>
        <a
            v-if="
                (!item.key || item.items) &&
                item.visible !== false &&
                (typeof item.visible !== 'function' || item.visible())
            "
            :href="item.url"
            @click="itemClick($event, item, index)"
            :class="item.class"
            :target="item.target"
            tabindex="0"
        >
            <span
                :class="item.icon + ' p-menuitem-icon layout-menuitem-icon'"
                data-pc-section="icon"
            ></span>
            <span class="layout-menuitem-text">{{ item.label }}</span>
            <span
                class="far fa-angle-down layout-submenu-toggler"
                v-if="item.items"
            ></span>
        </a>
        <router-link
            v-if="
                item.key &&
                !item.items &&
                item.visible !== false &&
                (typeof item.visible !== 'function' || item.visible())
            "
            @click="itemClick($event, item, index)"
            :class="[item.class, { 'active-route': checkActiveRoute(item) }]"
            tabindex="0"
            :to="item.key"
        >
            <span
                :class="item.icon + ' p-menuitem-icon layout-menuitem-icon'"
                data-pc-section="icon"
            ></span>
            <span class="layout-menuitem-text">{{ item.label }}</span>
            <span
                class="far fa-angle-down layout-submenu-toggler"
                v-if="item.items"
                data-pc-section="icon"
            ></span>
        </router-link>

        <ul
            ref="subMenuRef"
            :class="{ 'layout-root-submenulist': root }"
            v-if="
                item.items &&
                item.visible !== false &&
                (typeof item.visible !== 'function' || item.visible())
            "
        >
            <app-menu-item
                v-for="(child, i) in item.items"
                :key="child"
                :index="i"
                :item="child"
                :parentItemKey="itemKey"
                :root="false"
                :rootIndex="props.index"
            ></app-menu-item>
        </ul>
    </li>
</template>
