import { useUiStore } from '@/modules/primevue/stores/ui-store'
import { useCurrentUserStore } from '@/modules/user/stores/current-user-store'

/**
 * Handles error and loading screen during axios request
 * @param cb CallableFunction
 * @param onSuccess CallableFunction
 * @param onError CallableFunction
 */
export const axiosRequest = async (
    cb: CallableFunction,
    onSuccess?: CallableFunction,
    onError?: CallableFunction,
) => {
    const uiStore = useUiStore()
    const currentUserStore = useCurrentUserStore()

    uiStore.currentError = null
    uiStore.isLoading = true

    let cbData

    try {
        cbData = await cb()

        if (typeof onSuccess === 'function') {
            await onSuccess(cbData)
        }
    } catch (e: any) {
        const errorString = e.response ? e.response.status : e

        uiStore.currentError = errorString

        if (typeof onError === 'function') {
            await onError(e)
        } else if (currentUserStore.currentUser?.isDeveloper) {
            uiStore.ADD_TOAST({
                severity: 'error',
                detail: errorString,
            })
        }
    } finally {
        uiStore.isLoading = false
    }

    return cbData?.data ?? null
}
