// Components
import AppLayout from '@/modules/primevue/components/AppLayout.vue'
import { RouteRecordRaw } from 'vue-router'
import { removeTrailingSlash } from '@/modules/primevue/helpers/string-manipulation'

const basePath = '/ab'

export const AddressBookRouter: Array<RouteRecordRaw> = [
    {
        path: basePath,
        name: 'Rubrica',
        redirect: `${basePath}/index`,
        component: AppLayout,
        meta: {
            nameKey: 'SLS.address_book',
        },
        children: [
            {
                path: `${basePath}/index`,
                name: 'Indice',
                meta: {
                    title: 'Elenco schede rubrica',
                },
                component: () =>
                    import(
                        '@/modules/address-book/components/AddressBookIndex.vue'
                    ),
            },
            {
                path: `${basePath}/:id`,
                name: 'Scheda rubrica',
                meta: {
                    // title: '◌',
                },
                redirect: (to) => removeTrailingSlash(to.fullPath) + '/general',
                component: () =>
                    import(
                        '@/modules/address-book/components/AddressBookSheet.vue'
                    ),
                children: [
                    {
                        path: 'general',
                        name: 'Scheda rubrica generale',
                        component: () =>
                            import(
                                '@/modules/address-book/components/AddressBookSheetGeneralData.vue'
                            ),
                    },
                    {
                        path: 'supplier',
                        name: 'Scheda rubrica fornitore',
                        component: () =>
                            import(
                                '@/modules/address-book/components/AddressBookSheetSupplierData.vue'
                            ),
                    },
                    {
                        path: 'customer',
                        name: 'Scheda rubrica cliente',
                        component: () =>
                            import(
                                '@/modules/address-book/components/AddressBookSheetCustomerData.vue'
                            ),
                    },
                    {
                        path: 'addresses',
                        name: 'Scheda rubrica indirizzi',
                        component: () =>
                            import(
                                '@/modules/address-book/components/AddressBookSheetAddress.vue'
                            ),
                    },
                    {
                        path: 'contacts',
                        name: 'Scheda rubrica contatti',
                        component: () =>
                            import(
                                '@/modules/address-book/components/AddressBookSheetContacts.vue'
                            ),
                    },
                ],
            },
        ],
    },
]
